import AnimatedContainer from "../../container/animatedContainer";
import { BsTicketPerforated } from "react-icons/bs";
import { CgChevronRightO } from "react-icons/cg";
import { CgChevronLeftO} from "react-icons/cg";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import {useEffect, useState} from "react";
import { motion } from "framer-motion";
import { useParams} from "react-router-dom";
import {useGetSingleEventQuery} from "../../api/event";
import {Ticket} from "../../types/event";
import {usePaymentMutation} from "../../api/tickets";
import {useForm} from "react-hook-form";
import mtn_logo from "../../assets/images/mtn-logo.png";
import {useAppSelector} from "../../store";
import {PaymentPayload} from "../../types/tickets";
import {useVerifyPromoCodeMutation} from "../../api/promo_code";

type SelectedTicket = { pricing: Ticket, ticket_number: number  };

export default function PastEventDetails() {
    const [cerror, setCerror] = useState(false);
    const { slug } = useParams();
    const { data, isLoading  } = useGetSingleEventQuery({ slug: slug ?? '' });
    const [steps, setSteps] = useState<1 | 2 | 3>(1)
    const [selected, setSelected] = useState<SelectedTicket[]>([]);
    const [paymentMode, setPaymentMode] = useState<'momo' | 'code'>('momo');
    const [payment, payOptions] = usePaymentMutation();
    const [verify, promoOptions] = useVerifyPromoCodeMutation();
    const { user } = useAppSelector((state) => state.userState)

    const { handleSubmit, register, formState: { errors }, reset, watch, setError } = useForm({
        defaultValues: {
            email: user?.email ?? '',
            name: user?.name ?? '',
            phoneNumber: '',
            payment_mode: paymentMode,
            promoCode: null,
            personalPhoneNumber: user?.phoneNumber.substring(0, 3) ?? '',
        }
    });

    const getTicketNumber = (id: string): number => {
        const selectedTicket = selected.find(ticket => ticket.pricing.id === id);
        return selectedTicket ? selectedTicket.ticket_number : 0;
    };

    const addToSelected = (sP: Ticket) => {
        setSelected(prevSelected => {
            const existingTicket = prevSelected.find(ticket => ticket.pricing.id === sP.id);

            if (existingTicket) {
                return prevSelected.map(ticket =>
                    ticket.pricing.id === sP.id ? { ...ticket, ticket_number: ticket.ticket_number + 1 } : ticket
                );
            } else {
                return [...prevSelected, { pricing: sP, ticket_number: 1 }];
            }
        });
    };

    const removeFromSelected = (pricingIdToRemove: string) => {
        setSelected(prevSelected =>
            prevSelected.filter(ticket => ticket.pricing.id !== pricingIdToRemove)
        );
    };

    const getTotal = () => {
        let totalQ = 0;
        selected.map((g, index) => {
            totalQ = totalQ + (g.ticket_number * g.pricing.price);
            return totalQ
        })

        return totalQ;
    }

    useEffect(() => {
        setCerror(false);
    }, [steps]);

    const handlePayment = (data: any) => {
        const refined = {
            ...data,
            personalPhoneNumber: `2507${data.personalPhoneNumber}`,
            phoneNumber: `2507${data.phoneNumber}`,
            pricingIds: selected.map((it) => {
                return {
                    pricingId: it.pricing.id,
                    ticket_number: it.ticket_number,
                }
            })
        } as PaymentPayload
        payment({ status: !!user, data: refined });
        console.log(refined);
    }

    useEffect(() => {
        if(payOptions.isSuccess) {
            setSteps(1);
            setSelected([]);
            reset();
        }
    }, [payOptions.isSuccess]);

    return (
        <AnimatedContainer>
            { isLoading ? <div className={'w-full min-h-[500px] flex justify-center items-center text-center text-gray-500'} >One moment Please...</div> : null }
            { data &&
                <AnimatedContainer>
                    <div className={'min-h-[600px] flex justify-center flex-col items-start py-10 md:px-10'}>
                        <div
                            className="flex flex-col md:flex-row items-start justify-center gap-10 w-full md:w-[90%] px-3">
                            <div className="w-full md:w-4/6 bg-darker h-44 md:h-[25rem] overflow-clip rounded-3xl"
                                 style={{
                                     background: `url('${data.image}')`,
                                     backgroundSize: 'cover',
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPosition: 'center'
                                 }}></div>

                            <div className="flex px-3 md:px-0 flex-col gap-2 w-full md:w-2/6">
                                <h1>Event Details</h1>
                                <h1 className={'text-2xl font-bold'}>{data.title}</h1>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>Start Time</h2>
                                    <p>{new Date(data?.date ?? '').toDateString()}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>End Time</h2>
                                    <p>{new Date(data?.endDate ?? '').toDateString()}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>Location</h2>
                                    <p>{data?.location}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>Status</h2>
                                    <p>{data?.status === 'active' ? 'Active' : 'Inactive'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatedContainer>
            }
        </AnimatedContainer>
    )
}