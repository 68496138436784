import { IoLogoInstagram, IoLogoLinkedin, IoLogoFacebook } from "react-icons/io5";
import { PiXLogo } from "react-icons/pi";
import logo from '../assets/images/ishema_logo_light.png';
import {Link} from "react-router-dom";

export default function MainFooter() {
    return (
        <footer className={'w-full bg-darker text-white p-10'}>
            <div className="grid-cols-1 md:grid-cols-3 grid gap-4 border-b-2 pb-8 border-white/20">
                <div className="w-full">
                    <div className="flex flex-row items-center">
                        <p className={'w-20'}>Email Us</p>
                        <div className="h-[1px] w-full bg-white/20"></div>
                    </div>
                    <div className="mt-5 text-gray-500">info@ishemahub.com</div>
                </div>

                <div className="w-full">
                    <div className="flex flex-row items-center">
                        <p className={'w-20'}>Call Us</p>
                        <div className="h-[1px] w-full bg-white/20"></div>
                    </div>
                    <div className="mt-5 text-gray-500">+250 792 400 474</div>
                </div>

                <div className="w-full">
                    <div className="flex flex-row items-center">
                        <p className={'w-20'}>Find Us</p>
                        <div className="h-[1px] w-full bg-white/20"></div>
                    </div>
                    <div className="mt-5 flex flex-row gap-5 justify-start text-xl text-gray-500">
                        <IoLogoFacebook/>
                        <PiXLogo/>
                        <IoLogoLinkedin/>
                        <IoLogoInstagram/>
                    </div>
                </div>
            </div>

            <div className="grid-cols-1 mt-5 md:grid-cols-3 grid gap-4 border-b-2 pb-8 border-white/20">
                <div className="w-full">
                    <div className="flex flex-col items-start gap-2 py-2">
                        <img src={logo} alt={'ISHEMA TICKETS'} className={'w-10'}/>
                        <p className={'text-gray-500'}>Downtown, MAKUZA Parking Towers</p>
                        <p className={'text-gray-500'}>5th Floor. Kigali, Rwanda</p>
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex flex-col items-start gap-2 py-2">
                        <p className={'w-20'}>Quick Links</p>
                        <div className="flex flex-wrap w-full">
                            <Link to={''} className={'text-gray-500 w-1/2'}>Tickets</Link>
                            <Link to={''} className={'text-gray-500 w-1/2'}>Categories</Link>
                            <Link to={''} className={'text-gray-500 w-1/2'}>Discover</Link>
                            <Link to={''} className={'text-gray-500 w-1/2'}>About Us</Link>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <div className="flex flex-col items-start gap-2 py-2">
                        <p className={'w-28'}>Get Support</p>
                        <div className="flex flex-wrap w-full">
                            <Link to={''} className={'text-gray-500 w-1/2'}>Help</Link>
                            <Link to={''} className={'text-gray-500 w-1/2'}>Our Work</Link>
                            <Link to={''} className={'text-gray-500 w-1/2'}>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                @ Ishema tickets 2024
            </div>
        </footer>
    );
}
