import logo from '../assets/images/ishema_logo_dark.png'
import {NavLink, useNavigate} from "react-router-dom";
import SearchBanner from "./searchBanner";
import {LoginAnimatedButton} from "./misc";
import { LuMenu } from "react-icons/lu";
import { HiXMark } from "react-icons/hi2";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store";
import {useWhoamiQuery} from "../api/auth";
import {storage} from "../utils";
import {userSlice} from "../store/slices/userSlice";

export default function MainHeader() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userState);
    const dispatch = useAppDispatch();
    const nav_links = [
        {
            title: 'Home',
            location: ''
        },
        {
            title: 'Discover',
            location: 'events'
        },
        {
            title: 'Contact Us',
            location: 'contact-us'
        },
        {
            title: 'My Tickets',
            location: 'tickets'
        },

    ];

    const { isError, isLoading } = useWhoamiQuery(null, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (isError) {
            storage.removeToken();
            navigate('/');
        }
    }, [isError]);

    useEffect(() => {
        setOpen(false);
    }, [navigate]);

    const logOut = () => {
        storage.removeToken();
        dispatch(userSlice.actions.logout())
    }
    return (
        <div>
            <nav className={'flex shadow-lg md:fixed bg-white z-40 flex-row justify-between items-center w-full md:px-12 py-2'}>
                <div className={'flex w-full  flex-col md:flex-row items-center md:justify-between md:gap-10'}>
                    <div className="flex flex-row justify-between w-full md:w-auto px-3">
                        <img src={logo} alt={'ISHEMA TICKETS'} className={'w-10'}/>

                        <button onClick={() => setOpen(prev => !prev)} className={" md:hidden "}>
                            {open ? <HiXMark className={'text-3xl'}/> : <LuMenu className={'text-3xl'}/>}
                        </button>
                    </div>

                    {<ul className={`w-full ${open ? 'flex' : 'hidden'} transition-all md:flex md:w-auto items-center flex-col md:flex-row gap-5 `}>
                        {nav_links.map((item, index) => <li key={index} className={'font-semibold'}><NavLink
                            to={`/${item.location}`}>{item.title}</NavLink></li>)}
                        {user ? <li onClick={() => logOut()} className={'underline text-primary cursor-pointer'}>Log Out</li> : isLoading ? <div className={'h-10 w-24 animate-pulse rounded-full bg-gray-100'}></div> : <LoginAnimatedButton/>}
                    </ul>}
                </div>

            </nav>
            <SearchBanner/>
        </div>
    )
}