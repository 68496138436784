import { useEffect } from "react";
import {useWhoamiQuery} from "../api/auth";
import {useNavigate} from "react-router-dom";

export const useVerifyAuth = () => {
    const {data, isLoading, isError } = useWhoamiQuery({});
    const navigate = useNavigate();

    useEffect(() => {
        if(isError) {
            navigate('/login')
        }
    }, [isError]);

    return {
        data,
        isLoading,
    };

}
