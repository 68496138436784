import HomeSlide from "./HomeSlide";
import NextEvents from "./NextEvents";
import ExploreGrid from "./ExploreGrid";
import AnimatedContainer from "../../container/animatedContainer";
import PastEvents from "./PastEvents";

export function HomePage() {
    return (
        <div className={'w-full'}>
            <div className=" text-white relative">
                <div className="absolute h-80 w-full bg-darker -z-10"></div>
                <AnimatedContainer>
                    <div className="w-full p-5 items-center  flex flex-col gap-3">
                        <div className="w-full max-w-[1700px] flex flex-col md:flex-row gap-3">
                            <div className="w-full md:w-8/12 flex flex-col gap-2">
                                <h1 className={'font-bold text-lg px-5'}>Trending Events</h1>
                                <div className="md:px-5 pt-0">
                                    <HomeSlide/>
                                </div>
                            </div>

                            <div className="w-full md:w-4/12 flex flex-col gap-2">
                                <h1 className={'font-bold text-lg px-5'}>Next Events</h1>
                                <div className="md:px-5 pt-0">
                                    <NextEvents/>
                                </div>
                            </div>
                        </div>
                        <ExploreGrid/>
                        <PastEvents />
                    </div>
                </AnimatedContainer>
            </div>
        </div>
    );
}
