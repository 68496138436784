import { PiTicket } from "react-icons/pi";
import {Link} from "react-router-dom";
import {useGetEventsQuery} from "../../api/event";
import {EventCard} from "../../components/misc";

export default function ExploreGrid() {
    const { data, isLoading } = useGetEventsQuery({  });
    return (
        <div className="p-5 w-full max-w-[1700px]">
            <h1 className={'text-lg text-black px-1 font-bold flex flex-row gap-2 items-center mb-5'}>Explore
                Events <div className="text-primary">{(data?.list && data.list.length >= 1) ? data?.list.length : ''}</div></h1>

            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4    gap-4">
                {isLoading && [1,1,1,1].map((item, index) => <div key={index} className="w-full h-52 border rounded-xl bg-gray-100 animate-pulse"></div>)}
                {data?.list.map((item, index) =>
                    <EventCard key={index} slug={item.slug} title={item.title} date={new Date(item.date).toLocaleDateString('en-US', {
                        weekday: 'long', // 'Saturday'
                        day: '2-digit', // '14'
                        month: 'short', // 'Oct'
                        year: 'numeric' // '2012'
                    })} time={new Date(item.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} status={item.status === 'active'} image={item.image}/>
                )}
                {data ? data.list.length === 0 && <div className={'py-20 flex col-span-4 justify-center text-gray-500 items-center'}>No Event Available Yet</div> : null }
            </div>

            <div className="my-2 text-black w-full flex items-center justify-center text-lg font-bold cursor-pointer">
                <Link to={'/events'}  className={'hover:underline transition-all'}>Load More</Link>
            </div>
        </div>
    )
}