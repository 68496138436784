import {Link} from "react-router-dom";
import {PiTicket} from "react-icons/pi";

export const LoginAnimatedButton = () => {
    return (
        <Link  to={'/login'} className="rounded-full overflow-clip flex items-center justify-center cursor-pointer">
            <div
                className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold shadow text-indigo-600 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 dark:bg-primary dark:text-white dark:hover:text-gray-200 dark:shadow-none group"
            >
                        <span
                            className="absolute bottom-0 left-0 w-full h-0 transition-all duration-150 ease-in-out bg-black group-hover:h-full"
                        ></span>
                <span
                    className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12"
                >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              fill="none"
                              className="w-5 h-5 text-white"
                          >
                            <path
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                strokeWidth="2"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                            ></path>
                          </svg>
                        </span>
                <span
                    className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200"
                >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              fill="none"
                              className="w-5 h-5 text-primary"
                          >
                            <path
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                                strokeWidth="2"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                            ></path>
                          </svg>
                        </span>
                <span
                    className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white dark:group-hover:text-gray-200"
                >Sign In</span
                >
            </div>
        </Link>
    )
}

export function EventCard({title, time, slug, date, status, image}: { title: string, slug: string, date: string, time: string, status: boolean, image: string }) {
    return (
        <div className="p-2 w-full text-black rounded-2xl flex flex-col gap-3">
            <div className="bg-yellow-600 h-44 w-full rounded-2xl" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}></div>

            <div className="flex flex-col w-full p-2">
                <h2 className={'font-bold text-lg'}>{title}</h2>
                <p className={'text-gray-400'}>{date}</p>
                <div className="w-full flex flex-wrap justify-between">
                    <div className="flex flex-col gap-0 w-1/2">
                        <p className={'text-gray-400'}>Time</p>
                        <div className="font-semibold">{time}</div>
                    </div>

                    <div className="flex flex-col gap-0 w-1/2">
                        <p className={'text-gray-400'}>Status</p>
                        <div className="font-semibold">{status ? 'Active' : 'Inactive'}</div>
                    </div>
                </div>

                <div className="flex justify-center items-center mt-3">
                    <Link to={`/event/${slug}`} className={'bg-primary flex justify-center items-center gap-2 p-2 py-3 text-white w-full rounded-full'}>
                        <PiTicket /> Book Ticket
                    </Link>
                </div>
            </div>
        </div>
    )
}

export function PastEventCard({title, time, slug, date, status, image}: { title: string, slug: string, date: string, time: string, status: boolean, image: string }) {
    return (
        <div className="p-2 w-full text-black rounded-2xl flex flex-col gap-3">
            <div className="bg-yellow-600 h-44 w-full rounded-2xl" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}></div>

            <div className="flex flex-col w-full p-2">
                <h2 className={'font-bold text-lg'}>{title}</h2>
                <p className={'text-gray-400'}>{date}</p>
                <div className="w-full flex flex-wrap justify-between">
                    <div className="flex flex-col gap-0 w-1/2">
                        <p className={'text-gray-400'}>Time</p>
                        <div className="font-semibold">{time}</div>
                    </div>

                    <div className="flex flex-col gap-0 w-1/2">
                        <p className={'text-gray-400'}>Status</p>
                        <div className="font-semibold">{status ? 'Active' : 'Inactive'}</div>
                    </div>
                </div>

                <div className="flex justify-center items-center mt-3">
                    <Link to={`/event/past/${slug}`} className={'bg-primary flex justify-center items-center gap-2 p-2 py-3 text-white w-full rounded-full'}>
                        <PiTicket /> Details
                    </Link>
                </div>
            </div>
        </div>
    )
}