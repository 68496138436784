import { CiSearch } from "react-icons/ci";
import { LiaGlassCheersSolid } from "react-icons/lia";
import { VscMic } from "react-icons/vsc";
import { CiBasketball } from "react-icons/ci";
import { HiDotsHorizontal } from "react-icons/hi";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useGetEventsQuery} from "../api/event";
import { Link } from 'react-router-dom';

export default function SearchBanner() {
    const buttons_links = [
        {
            icon: <LiaGlassCheersSolid />
        },
        {
            icon: <VscMic />
        },
        {
            icon: <CiBasketball />
        },
        {
            icon: <HiDotsHorizontal />
        }
    ];

    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const { data, isFetching } = useGetEventsQuery({ search: searchTerm });

    return (
        <div className={'w-full pt-[65px] px-5 md:px-12 bg-darker text-white flex flex-col md:flex-row justify-between gap-2'}>
            <div className="w-full relative">
                <CiSearch className={'absolute text-2xl z-8 mt-[24px] ml-2 text-white'} />
                <input onChange={handleInputChange} value={searchTerm} type={'search'} placeholder={'Search Events'} className={'relative bg-gray-600/60 py-3 px-10 my-3 rounded-full focus:border-none border-gray-400/30 focus:outline-none active:ring-0 active:border-none w-full'} />
                {searchTerm !== '' ?
                    <div className="absolute bg-gray-50 z-[999] w-full flex flex-col items-center overflow-y-scroll shadow h-64 mt-1 text-black border rounded-lg">
                        {isFetching ? 'Loading...' : data?.list.length === 0 ? 'No events found.' : data?.list.map((item, index) => (
                            <Link to={`/event/${item.slug}`} className={'p-2 w-full flex flex-row gap-3 border-b cursor-pointer'}>
                                <div className="bg-yellow-600 h-20 w-20 rounded-2xl" style={{
                                    background: `url('${item.image}')`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center'
                                }}></div>
                                <div className="flex flex-col gap-1 py-2">
                                    <p className={'text-xl font-semibold'}>{item.title}</p>
                                    <p className={'text-md font-semibold text-gray-500'}>{new Date(item.date).toLocaleDateString('en-US', {
                                        weekday: 'long',
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric'
                                    })}</p>
                                </div>
                            </Link>
                        ))}
                    </div> : null}
            </div>
            <div className="flex gap-4 flex-row justify-center pb-4 md:pb-0 md:gap-2 items-center">
                {buttons_links.map((item, index) => (
                    <div className={'p-5 bg-gray-600/60 rounded-full cursor-pointer'} key={index} >
                        {item.icon}
                    </div>
                ))}
            </div>
        </div>
    )
}