import {useGetEventsQuery, useGetPastEventsQuery} from "../../api/event";
import {EventCard} from "../../components/misc";
import AnimatedContainer from "../../container/animatedContainer";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

export default function AllPastEvents() {
    const [search, setSearch] = useState('');
    const { data, isFetching } = useGetPastEventsQuery({ search });
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const searchTerm = queryParams.get('search');
        if (searchTerm) {
            setSearch(searchTerm);
        }
    }, [location.search]);

    return (
        <AnimatedContainer>
            <div className="p-5 w-full max-w-[1700px]">
                <h1 className={'text-lg text-black px-1 font-bold flex flex-row gap-2 items-center mb-5'}>Past
                    Events <div className="text-primary">{data?.list.length}</div></h1>

                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4    gap-4">
                    {isFetching ? [1,1,1,1].map((item, index) => <div key={index} className="w-full h-52 rounded-xl bg-gray-100 animate-pulse"></div>) :
                        data?.list.length === 0 ? <div className={'py-20 flex col-span-4 justify-center text-gray-500 items-center'}>No Event Available Yet</div> :
                        data?.list.map((item, index) =>
                        <EventCard key={index} slug={item.slug} title={item.title} date={new Date(item.date).toLocaleDateString('en-US', {
                            weekday: 'long', // 'Saturday'
                            day: '2-digit', // '14'
                            month: 'short', // 'Oct'
                            year: 'numeric' // '2012'
                        })} time={new Date(item.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} status={item.status === 'active'} image={item.image}/>
                    )}
                </div>

                <div className="my-2 text-black w-full flex items-center justify-center text-lg font-bold cursor-pointer">

                </div>
            </div>
        </AnimatedContainer>
    )
}